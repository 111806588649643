const ContactPage = ({ data }) => {
  const hero = data.contactYaml.contactHero

  return (
    <Base>
      <PageMetadata page={data.metadata.siteMetadata.contactPage} />

      <main id="main-content" tabIndex="-1" className="sans-serif">
        <Hero
          heading={hero.heading}
          image={hero.image.childImageSharp.fluid}
          alt={hero.alt}
          objPosition={hero.objPosition}
          quote={hero.quote}
          source={hero.source}
          link={hero.link}
        />

        <Links
          contactLinks={data.allLinksContactYaml.edges}
          socialLinks={data.allLinksSocialYaml.edges}
        />
      </main>
    </Base>
  )
}

/*
 *
 * Queries
 * 
 */

export const query = graphql`
  query ContactQuery {
    metadata: site {
      siteMetadata {
        contactPage {
          description
          title
          url
        }
      }
    }
    contactYaml {
      contactHero {
        heading
        image {
          childImageSharp {
            fluid(maxWidth: 1120) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        alt
        objPosition
        quote
        source
        link
      }
    }
    allLinksContactYaml {
      edges {
        node {
          heading
          links {
            text
            href
          }
        }
      }
    }
    allLinksSocialYaml {
      edges {
        node {
          href
          srText
          fontSize
        }
      }
    }
  }
`

/*
 *
 * Imports & Exports
 * 
 */

import React from 'react'
import { graphql } from 'gatsby'

import Links from '../sections/ContactLinks'

import Base from '../components/Base'
import Hero from '../components/Hero'
import PageMetadata from '../components/PageMetadata'

export default ContactPage
