const Links = ({ contactLinks, socialLinks }) => (
  <section className="bg-near-white pv5">
    <div className="container pv4 sm:mv3">
      <h2 className="section-heading mb5">Get in Touch</h2>

      <p className="lh-copy" style={{ maxWidth: `50ch` }}>
        To reach Patrick for future performance or teaching engagements (including
        private lessons, masterclasses and summer programs), please use the links
        below:
      </p>

      <ContactLinks links={contactLinks} />
      <SocialLinks links={socialLinks} />
    </div>
  </section>
)

/*
 *
 * Contact Links
 *
 */

const ContactLinks = ({ links }) => (
  <nav aria-label="Contact links">
    <ul>{links.map((link, i) => <ContactLink key={i} link={link.node} />)}</ul>
  </nav>
)

const ContactLink = ({ link }) => (
  <li>
    <h3 className="section-subheading mt4 pt3">{link.heading}</h3>

    <ul className="mt3 lh-tall">
      {link.links.map((link, i) => (
        <li key={i}>
          <Anchor href={link.href} className="link-inline sm:f4">
            {link.text}
          </Anchor>
          <br />
        </li>
      ))}
    </ul>
  </li>
)

/*
 *
 * Social Links
 *
 */

const SocialLinks = ({ links }) => (
  <Fragment>
    <h4 className="section-subheading mt4 pt3">Social Media</h4>

    <nav aria-label="Social media links" className="mt3 pt1">
      <ul className="flex">
        {links.map((link, i) => <SocialLink key={i} link={link.node} />)}
      </ul>
    </nav>
  </Fragment>
)

const SocialLink = ({ link }) => {
  let icon = facebook
  if (link.srText === `Twitter`) icon = twitter
  if (link.srText === `YouTube`) icon = youtube

  return (
    <li className="flex-none flex mr3" style={{ width: `2.75em`, height: `2.75em` }}>
      <Anchor
        href={link.href}
        srText={`Follow on ${link.srText}`}
        className="link flex justify-center items-center w-100"
        style={{ fontSize: link.fontSize, padding: 0 }}
      >
        <Icon svg={icon} style={{ height: `auto` }} />
      </Anchor>
    </li>
  )
}

/*
 *
 * Imports & Exports
 * 
 */

import React, { Fragment } from 'react'

import Anchor from '../components/Anchor'
import Icon from '../components/Icon'

import facebook from '../svg/facebook-f.svg'
import twitter from '../svg/twitter.svg'
import youtube from '../svg/youtube.svg'

export default Links
